import React from 'react';
import workstation from '../../../static/workstation.png'

import './index.scss';

function Contact() {
  return (
    <section className="talk">
      <div className="talk__left">
          <div className="talk__image">
            <img src={workstation} alt="" />  
          </div>
      </div>
      <div className="talk__right section-padding bg-lightgrey">
        <p className="talk__text">I would love to hear from you. 
          Whether it is a project collaboration, job opportunity, 
          or just a chat. Feel free to reach me at: 
          <a className="talk__link" href="mailto:zaidirfan.zik@gmail.com">zaidirfan.zik@gmail.com</a>
        </p>
      </div>
    </section>
  );
}

export default Contact;